import {Layout} from 'antd';
import './AdminFooter.css';

function AdminFooter() {
    const year = new Date().getFullYear();
    return (
    <footer className="footer">
        {`Beraterklingel © Jonas Putscher ${year} | ${process.env.REACT_APP_VERSION}`}
    </footer>
    );
}

export default AdminFooter;