import ShowBerater from "./ShowBerater";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './BeraterList.css'
import getBerater from "../api";
import { useEffect, useState } from 'react';
import ScrollBar from './ScrollBar';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

function BeraterList() {   

    let msg = "";

    
    const { gs } = useParams();
    const { message } = useParams();
    const [berater, setBerater] = useState([]);
    {message === "eg" && (msg = "im Erdgeschoss ")}
    {message === "og" && (msg = "am Empfang ")}

    const handleSubmit = async (term) => {
        const result = await getBerater(gs);
        setBerater(result); 
    };

    useEffect(() => {
        handleSubmit();
    }, []);
    

    const renderedBerater = berater.map((berater) => {
        return (
            <Col key={berater.id}>
                <ShowBerater berater={berater} nachricht={msg}/>
            </Col>
        );
    });

    return (
    <Container className="container">
        <Row>
            {renderedBerater}
        </Row>
        {process.env.REACT_APP_SCROLLBAR && <ScrollBar/>}
    </Container>
    );
}

export default BeraterList;