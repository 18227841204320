import axios from "axios";
import { message, notification } from 'antd';

const deleteBerater = async (berater)=> {

    const id = berater.id;
    const response = await axios.delete(`${process.env.REACT_APP_API}/api/delete?id=${id}`, {
            headers: {
            'API-KEY': `${process.env.REACT_APP_APIKEY}`
            }
    })
    .then(function(result) {
        notification.error({
            message: `${berater.firstName} ${berater.lastName}`,
            description: `wurde gelöscht!`,
            placement: "top"
            })
        })
    .catch(error => {
        notification.error({
            message: `Es ist ein Fehler aufgetreten...`,
            description: `${error}`,
            placement: "top"
        })
    });
};

export default deleteBerater;