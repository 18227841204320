import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


function PopoverPositionedExample() {
    return (
          <OverlayTrigger
            trigger="click"
            placement='bottom'
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Header as="h3">Hilfe</Popover.Header>
                <Popover.Body>
                    Klicken Sie auf einen Berater um diesen zu benachrichten, dass Sie da sind. Der Berater bekommt eine Nachricht und wird Sie gleich abholen!
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="primary">Hilfe</Button>
          </OverlayTrigger>
        );
    }
    
  
export default PopoverPositionedExample;