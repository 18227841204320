import {Card, Button, Input, Form, Upload,Select, notification} from 'antd';
import { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import axios from 'axios';

function BeraterEdit({berater}) {
    const [firstName, setFirstName] = useState(berater.firstName);
    const [lastName, setLastName] = useState(berater.lastName);
    const [GS, setGS] = useState(berater.gs);
    const [matrixID, setMatrixID] = useState(berater.matrixID);
    const [profilePicture, setProfilePicture] = useState(berater.photos);


    const handleSubmit = async () => {
        const response = axios.put(`${process.env.REACT_APP_API}/api/update`, {
            "id": berater.id,
            "firstName": firstName,
            "lastName": lastName,
            "matrixID": matrixID,
            "gs": GS,
            "photos": profilePicture,
        },   
        {
            headers: {
            'API-KEY': `${process.env.REACT_APP_APIKEY}`
        }}).then(function(result){
            notification.success({
                message: `Berater ${firstName} ${lastName} wurde bearbeitet`,
                placement:'top'
            })
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }).catch(function(error){
            notification.error({
                message: `Es ist ein Fehler aufgetreten...`,
                description: `${error}`,
                placement: 'top'
            })
        })

    }

    const handleFileUpload = (info) => {
        const file = info.file;
        const reader = new FileReader();
    
        reader.addEventListener("load", () => {
          setProfilePicture(reader.result.substring(22));
          console.log(profilePicture);
        });
    
        reader.readAsDataURL(file);
       }


    return (
        <div style={{padding: 15}}>
        <Card title={`Berater ${firstName} ${lastName} bearbeiten`} 
        style={{width: 600}}
        >
        
        <Form onFinish={handleSubmit}>

        <Form.Item
        name="firstName"
        >
            <Stack direction="horizontal" style={{flexDirection: 'center'}}>
                <div className="me-auto">Vorname: </div>
                <div className="p-2">
                    <Input value={firstName} onChange={(e) => {setFirstName(e.target.value)}}></Input>
                </div>
            </Stack>
            
        </Form.Item>

        <Form.Item>
            <Stack direction="horizontal">
                <div className="me-auto">Nachname: </div>
                <div className="p-2">
                    <Input value={lastName} onChange={(e) => {setLastName(e.target.value)}}></Input>
                </div>
            </Stack>
        </Form.Item>


        <Form.Item>
            <Stack direction="horizontal">
                <div className="me-auto">Geschäftsstelle: </div>
                <div className="p-2">
                <Select placeholder='Geschäftsstelle wählen' value={GS} onChange={(e) => {setGS(e)}} style={{width: 300}}>
                    <Select.Option value="000">000 Ansbach</Select.Option>
                    <Select.Option value="045">045 Schwabach</Select.Option>
                    <Select.Option value="089">089 Rothenburg</Select.Option>
                    <Select.Option value="057">057 Herrieden</Select.Option>
                    <Select.Option value="dis">deaktiviert</Select.Option>
                </Select>
                </div>
            </Stack>
        </Form.Item>

        <Form.Item>
            <Stack direction="horizontal">
                <div className="me-auto">Matrix-ID: </div>
                <div className="p-2">
                    <Input value={matrixID} onChange={(e) => {setMatrixID(e.target.value)}}></Input>
                </div>
            </Stack>
        </Form.Item>

        <Form.Item>
            <Stack direction="horizontal">
                <div className="me-auto">Profilbild: </div>
                <div className="p-2">
                <Upload.Dragger  multiple={false} accept=".png" showUploadList={false} customRequest={handleFileUpload}>
                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                        auswählen oder Drag & Drop
                    </div>
                 </Upload.Dragger> 
                    <Card size="small" style={{margin: 10}}>
                        <div style={{fontWeight: 100}}><img alt="profilbild" src={`data:image/png;base64, ${profilePicture}`} width="50px" style={{borderRadius: 5, marginRight: 20}}></img> <b>Profilbild Vorschau</b></div>
                    </Card>
                </div>
            </Stack>
        </Form.Item>

        <Form.Item>
                <div className="p-1 me-auto">
                    <Button type="primary" htmlType='submit'>Änderung speichern</Button>
                </div>
        </Form.Item>
        </Form>
        
        </Card>
        </div>
    );
}

export default BeraterEdit;