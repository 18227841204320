import getBerater from '../api.js';
import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import deleteBerater from './BeraterDelete';
import { Modal, Divider, Switch, Typography, Select, Skeleton, notification} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import BeraterCreateForm from './BeraterCreateForm.js';
import BeraterEdit from './BeraterEdit.js';
import axios from 'axios';

function AdminList() {
    const [berater, setBerater] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentBerater, setCurrentBerater] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const { Text } = Typography;
    const [beraterEdit, setBeraterEdit] = useState(false);
    const [currentGS, setCurrentGS] = useState('000');
    const [loading, setLoading] = useState(true);
    

    const handleOk = () => {
        deleteBerater(currentBerater).then(handleSubmit);
        setIsModalOpen(false);
        setIsDisabled(false);
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsDisabled(false);
    };

    const handleSubmit = async (term) => {
        setLoading(true);
        const result = await getBerater('all');
        setBerater(result); 
        setLoading(false);
    };

    useEffect(() => {
        handleSubmit();
    }, []);
       
    const editBerater = (berater) => {
        setCurrentBerater(berater);
        setBeraterEdit(false);
        setBeraterEdit(true);
        
    }

    function disalbeBerater(berater) {
        setBeraterEdit(false);
        setCurrentBerater(berater);
        const response = axios.put(`${process.env.REACT_APP_API}/api/update`, {
            "id": berater.id,
            "firstName": berater.firstName,
            "lastName": berater.lastName,
            "matrixID": berater.matrixID,
            "gs": "dis",
            "photos": berater.photos,
        }).then(function(result){
            notification.success({
                message: `Berater ${berater.firstName} ${berater.lastName} wurde deaktiviert`,
                placement:'top'
            })
            setTimeout(() => {
                handleSubmit();
            }, 1000)
        }).catch(function(error){
            notification.error({
                message: `Es ist ein Fehler aufgetreten...`,
                description: `${error}`,
                placement: 'top'
            })
        })
  
    }

    function handleBeraterDelete(berater) {
        setIsModalOpen(true);
        setIsDisabled(true);
        setCurrentBerater(berater);
    }

    const filterGS = (value) => {
        setBeraterEdit(false);
        setCurrentGS(value);
    }

    const renderedBerater = berater.map((berater) => {
        return (
        <div key={berater.id}>
            {berater.gs === currentGS &&
            <ListGroup.Item 
                as="li" 
                className="d-flex justify-content-start align-items-center"
                style={{borderRadius: 5, margin: 1}}
            >
                <MenuOutlined style={{fontSize: 15, marginRight: 20}}/>
                <img 
                alt="Profilbild"
                src={`data:image/png;base64, ${berater.photos}`} 
                width="90px"
                style={{borderRadius: 10}}
                ></img>
                <div className="ms-2 me-auto">
                    {berater.firstName} 
                    <div className="fw-bold"> {berater.lastName}</div>
                    {berater.gs}
                </div>

                <Stack direction="horizontal" gap={3}>
                    
                    {berater.gs !== "dis" && <div><Text>deaktivieren</Text> <Switch onChange={() => {disalbeBerater(berater)} }/></div>}
                    <Button onClick={() => { editBerater(berater) }} variant="outline-success"> bearbeiten </Button>
                    <Button onClick={() => { handleBeraterDelete(berater) }} variant="outline-danger" disabled={isDisabled}> löschen </Button>
                </Stack>
                
            </ListGroup.Item>
    }
            {beraterEdit && (berater.id === currentBerater.id)  && 
            <div>
            <Stack direction='horizontal'>
                <BeraterEdit berater={currentBerater}/> 
                <Button variant="outline-danger" onClick={() => {setBeraterEdit(false)}}> Schließen</Button>
            </Stack>
            </div>}
            
        </div>
        );
    });

    return (
        <div className="main">
            <Modal title="Bist du dir sicher?" open={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
                <Stack direction='horizontal' gap={2}>
                    <img alt="Vorschaubild" src={`data:image/png;base64, ${currentBerater.photos}`} width="60px"></img>
                    <b>{currentBerater.firstName} {currentBerater.lastName} </b>wird von der Klingelseite gelöscht!
                </Stack>
            </Modal>
        
            <ListGroup>
            <Stack direction="horizontal" gap={4}>   
                <b>Filter Geschäftsstelle:</b>
                <Select placeholder='Geschäftsstelle wählen' onSelect={filterGS} defaultValue={['000']} style={{width: 500}}>
                    <Select.Option value="000">000 Ansbach</Select.Option>
                    <Select.Option value="045">045 Schwabach</Select.Option>
                    <Select.Option value="089">089 Rothenburg</Select.Option>
                    <Select.Option value="057">057 Herrieden</Select.Option>
                    <Select.Option value="dis">Deaktivierte Berater</Select.Option>
                </Select>

            </Stack>
            {currentGS !== "dis" && <Divider orientation='center'>Aktive Berater</Divider>}
            {currentGS === "dis" && <Divider orientation='center'>Deaktivierte Berater</Divider>}
                {loading &&  <Skeleton active />}
                {renderedBerater}
            </ListGroup>
            <div>   
                <Divider orientation='center'>Berater hinzufügen</Divider>
                <BeraterCreateForm onClickButton={handleSubmit}/>
            </div>  

        </div>
    );
}

export default AdminList;