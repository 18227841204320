import axios from "axios";
import { message, notification } from 'antd';


const NotifyBerater = async (berater, nachricht) => {

    var token;
    const room = berater.matrixID;

    const authenticate = await axios.post("https://matrix.vr-mw.de/_matrix/client/v3/login", {
        "type": "m.login.password",
        "identifier": {
            "type": "m.id.user",
            "user": "beraterklingel"
        },
        "password": "Palm0769#"
    }).then(function (result) {
        token = result.data.access_token;
        console.log(result);
    })

    const url = `https://matrix.vr-mw.de/_matrix/client/v3/rooms/${room}/send/m.room.message?access_token=${token}`

    const sendMessage = await axios.post(url, {
        "body": `Hallo ${berater.firstName}, \ndein Kunde wartet ${nachricht} in der Geschäftsstelle ${berater.gs}`,
        "msgtype": "m.text"
    })
    .then(function (result) {
        notification.success({
            message: `${berater.firstName} ${berater.lastName}`,
            description: `wurde benachrichtigt!`,
            placement: "top"
        })       
    })
    .catch(function (error) {
        if (error.response) {
            notification.error({
                message: `Es ist ein Fehler aufgetreten...`,
                description: `${error}`,
                placement: "top"
            
            })
        }
    }
    );
}

export default NotifyBerater;