import React, { useEffect } from 'react';
import BeraterList from './components/BeraterList';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import  "./app.css";
import AdminShow from './components/AdminShow';
import Header from './components/Header';

function App() {

    useEffect(() => {
        document.title = "Beraterklingel";
    }, []);

    return (
    <Router>
        <Header text={"Sie haben einen Termin? Benachrichtigen Sie Ihren Berater!"} helpButton={true} style={{top: 0, position: "fixed"}}/>
        <Routes>
            <Route path={process.env.REACT_APP_ktoken}>
                <Route path="kundensicht/:gs" element={<BeraterList />} />
                <Route path="kundensicht/:gs/:message" element={<BeraterList />} />
            </Route>
            <Route path={process.env.REACT_APP_atoken}>
                <Route path="admin" element={<AdminShow />}/>
            </Route>
        </Routes>            
    </Router>
    );
}

export default App;