import AdminList from './AdminList';
import AdminFooter from './AdminFooter';
import './AdminShow.css'

function AdminShow() {
return (
<div>
    <div className="admin-main">
        <AdminList />
    </div>
    <AdminFooter />
</div>
);
}

export default AdminShow;