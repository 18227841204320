
import { FloatButton } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

function ScrollBar() {
    return (
        <FloatButton.Group shape="square">
             <FloatButton 
                onClick={() => window.scrollBy(0, -250)}
                type="primary"
                style={{}}
                icon={<ArrowUpOutlined/>}
                
            />
            <FloatButton
                onClick={() => window.scrollBy(0, 250)}
                type="primary"
                style={{}}
                icon={<ArrowDownOutlined />}
            />
        </FloatButton.Group>
    );

}

export default ScrollBar;