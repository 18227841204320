import axios from "axios";

const createMatrixRoom = async (surname, lastname) => {


    const response= await axios.post(`${process.env.REACT_APP_MATRIXSERVER}?access_token=syt_YmVyYXRlcmtsaW5nZWw_KgkMzzAamUgmAEZVctcL_1tdPPV`, {
        headers: {
            'Access-Control-Allow-Origin': '*'
        },

        "creation_content": {
            "m.federate": false
        },
        "invite": [`@${lastname.toLowerCase()}.${surname.toLowerCase()}:matrix.vr-mw.de`],
        "name": `Beraterklingel ${surname} ${lastname}`,
        "preset": "private_chat",
        "is_direct": "true",
        "topic": "Raum für Benachrichtigungen der Beraterklingel"
        }
    )

    const matrixID = (await response).data.room_id;
    return(matrixID);
}

export default createMatrixRoom;