import { Form, Input, Upload, Select, Button, Card, Flex, Popover, Typography } from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons'
import { useState } from 'react';
import addBerater from '../media/add.png';
import createBerater from './BeraterCreate';
import createMatrixRoom from './CreateMatrixRoom';

function BeraterCreateForm(reload) {

    const { Text, Link } = Typography;
    const {onClickButton} = reload;
    const [form] = Form.useForm();
    const [profilepicture, setProfilePicture] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [raumID, setRaumID] = useState();
    const [alert, setAlert] = useState(false);

   const handleFormSubmit = (values) => {
        
        createBerater(values, profilepicture);

        setTimeout(() => {
            onClickButton();
            form.resetFields();
        }, 1000);
       
   }

   const handleFileUpload = (info) => {
    const file = info.file;
    const reader = new FileReader();

    

    reader.addEventListener("load", () => {
      setProfilePicture(reader.result.substring(22));
    });

    reader.readAsDataURL(file);
    setIsUploaded(true);
   }

   const handleCreateRoom = async (surname, lastname) => {

        if(surname == 'undefined' || lastname == null) {
            setAlert(true);
           
        } else {
            setIsLoading(true);
            var m_ID = await createMatrixRoom(surname, lastname);
            setRaumID(m_ID);
            //form.setFieldValue("matrixIDInput", raumID);
            setIsLoading(false);
        }
   } 

    return (
        <>
        <Card>
        <Form
            form={form}
            layout='vertical'
            onFinish={(values) => {handleFormSubmit(values)}}
            style={{maxWidth: 500}}
            >
            <Form.Item
                label="Vorname"
                name="Vorname"
                rules={[{ required: true, message: 'Bitte den Vorname des Beraters eingeben!' }]}
                
            >
                <Input placeholder='Vorname eingeben!'></Input>
            </Form.Item>
            <Form.Item
                label="Nachname"
                name="Nachname"
                rules={[{ required: true, message: 'Bitte den Nachnamen des Beraters eingeben!' }]}
            >
                <Input placeholder='Nachnamen eingeben!'></Input>

            </Form.Item>
            <Form.Item
                label="Matrix Raum-ID"
                name="matrixID"
                rules={[{ required: true, message: 'Bitte die Raum-ID des Beraters eingeben!' }]}
            >
            <Flex gap="middle">
                <Input placeholder='Raum-ID eingeben!' name="matrixIDInput" value={raumID}  onChange={(e) => setRaumID(e.target.value)}></Input>
                <Button loading={isLoading} type="primary" onClick={(surname, lastName) => {handleCreateRoom(form.getFieldValue("Vorname"), form.getFieldValue("Nachname"))}}>Raum erstellen</Button>  
                <Popover placement="right" content="Bitte den Vor- und Nachnamen ausfüllen, damit ein Raum mit dieser Person erstellt werden kann!" title="Information"><InfoCircleOutlined /></Popover>
            </Flex>
            {alert && <Text type="danger">Bitte Vor- und Nachnamen ausfüllen!</Text>}
            </Form.Item>
            <Form.Item
                label="Geschäftsstelle"
                name="gs"
                rules={[{ required: true, message: 'Bitte die Geschäftsstelle eingeben!' }]}
            >
                <Select placeholder='Geschäftsstelle wählen'>
                    <Select.Option value="000">000 Ansbach</Select.Option>
                    <Select.Option value="045">045 Schwabach</Select.Option>
                    <Select.Option value="089">089 Rothenburg</Select.Option>
                    <Select.Option value="057">057 Herrieden</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Profilbild"
                name="Profilbild"
                rules={[{ required: true, message: 'Profilbild hochladen!' }]}
                valuePropName="photo"
            >

            <Upload.Dragger  multiple={false} accept=".png" customRequest={handleFileUpload} showUploadList={false}>
                    <div>
                        <img src={addBerater} width="20px" alt="Vorschaubild"></img>
                        <br />
                        auswählen oder Drag & Drop
                    </div>
                    
            </Upload.Dragger>
            </Form.Item>
            <Form.Item>
                {isUploaded && 
                <Card size="small">
                    <div style={{fontWeight: 100}}><img alt="Profilbild" src={`data:image/png;base64, ${profilepicture}`} width="50px" style={{borderRadius: 10}}></img> Profilbild wurde hochgeladen ...</div>
                </Card>}
    
            </Form.Item>
            <Form.Item>
                <Button htmlType='submit' type="primary">Berater hinzufügen</Button>
            </Form.Item>

        </Form>
        </Card>     
</>
    );
}

export default BeraterCreateForm;