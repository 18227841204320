import Hilfe from '../components/Overlay';
import logo from "../media/logo.png";

function Header({text, helpButton}) {
return(          
    <div className="header"> 
        <div className="header-title">
            <img src={logo} className="logo"></img>
            {text}
        </div>
        {helpButton && <Hilfe />}
    </div>
);
}

export default Header;