import axios from "axios";
import { notification } from 'antd';

const createBerater = (form, profilepicture) => {


    const response = axios.post(`${process.env.REACT_APP_API}/api/add`, {
        "firstName": form.Vorname,
        "lastName": form.Nachname,
        "matrixID": form.matrixID,
        "gs": form.gs,
        "photos": profilepicture,
    },       
    {
        headers: {
        'API-KEY': `${process.env.REACT_APP_APIKEY}`
    }
    },).then(function(result){
        notification.success({
            message: `Berater ${form.Vorname} ${form.Nachname} wurde erstellt`,
            placement:'top'
        })
        return result;
    }).catch(function(error){
        notification.error({
            message: `Es ist ein Fehler aufgetreten...`,
            description: `${error}`,
            placement: 'top'
        })
        throw error;
    })
}

export default createBerater;